<template>
  <div class="article_list_box">
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">
          {{ Data.name ? Data.name : columnName }}
        </span>
      </div>
      <img style="width: 100%; height: 100%" src="../../assets/img/title_1.jpg" alt="" />
    </div>
    <div class="article_list">
      <div class="article_list_left" v-if="is_show_menu !== '0____________'">
        <div class="article_list_title">
          {{ Data.parentName ? Data.parentName : columnName }}
        </div>
        <el-menu :unique-opened="true" :default-active="Data.id" class="el-menu-vertical-demo"
          background-color="#F2F2F2" text-color="#000" active-text-color="#C21D14">
          <div v-for="item in nav_list" :key="item.id">
            <el-submenu :index="item.id + ''" v-if="item.children.length">
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item v-for="data in item.children" :key="data.id" :index="data.id + ''"
                @click="handle_skip(data)"><span class="dian"></span>{{ data.name }}</el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.id + ''" v-else @click="handle_skip(item)">
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
      <div class="article_menu">
        <el-menu class="el-menu-demo" :unique-opened="true" text-color="#000" active-text-color="#C21D14">
          <el-submenu index="2" class="el-menu_">
            <template slot="title">{{
                Data.parentName ? Data.parentName : columnName
            }}</template>
            <div v-for="item in nav_list" :key="item.id">
              <el-submenu :index="item.id + ''" v-if="item.children.length" class="my_submenu">
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item v-for="data in item.children" :key="data.id" :index="data.id + ''"
                  @click="handle_skip(data)">{{ data.name }}</el-menu-item>
              </el-submenu>
              <el-menu-item :index="item.id + ''" v-else @click="handle_skip(item)">
                <span slot="title"><span class="dian1__"></span>{{ item.name }}</span>
              </el-menu-item>
            </div>
          </el-submenu>
        </el-menu>
      </div>
      <div class="article_list_right">
        <div class="article_list_right_top">
          <div class="article_list_right_title1">详情正文</div>
          <div class="article_list_right_tab">
            首页 >
            {{ Data.parentName ? Data.parentName : "" }}
            {{ Data.parentName ? `>${Data.name}` : columnName }}
          </div>
        </div>
        <div>
          <h3 class="text_title">{{ pageData.title }}</h3>
          <p class="text_time">
            <span style="margin-right:5px;" v-if="pageData.source">文章来源：<span style="">{{
                pageData.source
            }}</span><span style="margin-left:10px;">|</span></span>
            发布时间：<span>{{
                pageData.publishTime
            }}</span>
          </p>
          <video :style="{
            width: '100%',
            height: pageData.is_mp3 ? 'auto' : 'auto',
          }" :src="vod + pageData.video" v-if="pageData.video" controls="controls"></video>
          <div class="article_details" v-html="pageData.content"></div>
          <!-- && !pageData.is_mp3 -->
          <div class="download_box" @click="handle_download" v-if="pageData.attachment">
            <div class="download_item1">
              <img style="width: 27px; height: 27px" src="@/assets/img/lianjie.png" />
            </div>
            <div class="download_item2">{{ pageData.attachName }}</div>
            <div class="download_item3">
              <img src="@/assets/img/xz1.png" />
            </div>
          </div>
          <div style="width: 100%; height: 1px; background: #e5e5e5"></div>
          <div class="next_box">
            <div class="article_next">
              <div class="article_next_item" v-if="next_list.b1" @click="handle_next(next_list.b1)">
                上一篇：{{ next_list.b1 ? next_list.b1.title : next_list.b1 }}
              </div>
              <div class="article_next_item" v-if="next_list.b2" @click="handle_next(next_list.b2)">
                下一篇：{{ next_list.b2 ? next_list.b2.title : next_list.b2 }}
              </div>
            </div>
            <div class="article_return" @click="handle_article_return">
              返回
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_show_menu: this.$route.query.id,
      columnName: this.$route.query.name,
      pageData: {},
      nav_list: {},
      Data: {},
      next_list: {},
      // 根据名称获取展示树显示的id
      showTreeId: ''
    };
  },
  watch: {
    $route: "getId",
  },
  created() {
    // this.getData();
  },
  async mounted() {
    await this.handle_details();
    await this.handle_scrollTop();
  },
  methods: {
    handle_download() {
      window.open(this.http + this.pageData.attachment);
      // let link = document.createElement('a')
      // let url = this.http + this.pageData.attachment
      // fetch(url).then(res => res.blob()).then(blob => {
      //   link.href = window.URL.createObjectURL(blob)
      //   link.download = '111.png';
      //   document.body.appendChild(link)
      //   link.click();
      // })
    },
    handle_article_return() {
      window.history.go(-1);
    },
    handle_next(data) {
      console.log('点击上一篇', data);
      if (data.hasLink) {
        window.open(data.link)
      } else {
        this.$router.push({
          path: "/details",
          query: {
            id: this.$route.query.id,
            my_id: data.id,
          },
        });
      }
    },
    handle_scrollTop() {
      this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    },
    handle_skip(data) {
      this.$router.push({
        path: data.href,
        query: {
          column_id: data.columnId,
          id: data.id,
        },
      });
    },
    handle_details() {
      this.$axios({
        method: "get",
        url: `/api/web/cms/view?id=${this.$route.query.my_id}`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((res) => {
        this.pageData = res.data.data;
        if (res.data.data.attachment) {
          this.pageData = {
            ...res.data.data,
            is_mp3:
              res.data.data.attachment?.substr(
                res.data.data.video?.length - 3,
                3
              ),
          };
        }
        if (this.$route.query.id !== "0____________") {
          this.getData();
        }
      });
      this.$axios({
        method: "get",
        url: `/api/web/cms/neighbor?cmsArticleId=${this.$route.query.my_id}`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((res) => {
        this.next_list = {
          b1: res.data.data[0],
          b2: res.data.data[1],
        }
      });

    },
    getId() {
      this.is_show_menu = this.$route.query.id;
      this.handle_details();
      this.handle_scrollTop();
      if (this.$route.query.id !== "0____________") {
        this.getData();
      }
    },
    getData() {
      this.$axios({
        method: "get",
        url: `/api/web/navigation/tree_by_leaf?articleId=${this.$route.query.my_id}`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((res) => {
        this.nav_list = [...res.data[0].children];
        //console.log('左侧菜单树', this.nav_list);
        // let arr = this.nav_list;
        // arr.forEach(item => {
        //   if (item.name === this.$route.query.name) {
        //     this.showTreeId = item.id;
        //     if (this.showTreeId) {
        //       this.$nextTick(() => {
        //         this.is_show_menu = item.id;
        //       })
        //       console.log('显示id', this.showTreeId, this.is_show_menu);
        //     }
        //   } else {
        //     item.children.forEach(item1 => {
        //       if (item1.name === this.$route.query.name) {
        //         this.showTreeId = item1.id;
        //         if (this.showTreeId) {
        //           this.$nextTick(() => {
        //             this.is_show_menu = item1.id;
        //           })
        //           console.log('显示id', this.showTreeId, this.is_show_menu);
        //         }
        //       }
        //     })
        //   }
        // });
        this.Data = {
          parentName: res.data[0].name,
          id: this.$route.query.id,
          name: this.$route.query.name
        };
        this.$axios({
          method: "get",
          url: `/api/web/navigation/by_id?id=${this.$route.query.id}`,
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
        }).then((response) => {
          this.Data = {
            ...this.Data,
            name: response.data.data.name,
          };
        });
      });
    },
  },
};
</script>
<style scoped>
.download_item1 {
  width: 50px;
  height: 50px;
  background: #c6271e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download_item2 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.download_box {
  margin-bottom: 15px;
  padding-right: 10px;
  width: 440px;
  height: 50px;
  background: #f7f7f7;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
}

.article_next_item {
  cursor: pointer;
}

.download_item2 {
  margin-left: 10px;
}

.download_item3 {
  margin-left: auto;
}

.article_return {
  width: 92px;
  height: 40px;
  line-height: 40px;
  background: #c6271e;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.next_box {
  margin-top: 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.article_next {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 37px;
}

.text_title {
  text-align: center;
  font-size: 32px;
  line-height: 45px;
  margin: 40px 0 20px 0;
  word-break: break-all;
}

.text_time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

.article_details {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 38px;
  padding: 30px 0;
}

.article_details img {
  width: 100%;
  height: auto !important;
}

.article_details>>>table {
  width: 100%;
}

.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.article_list_right_tab {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 66px;
}

.article_list_right_title1 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 66px;
  border-bottom: #c21d14 3px solid;
}

.article_list_left {
  margin-right: 40px;
}

.article_list_right {
  flex: 1;
}

.article_list_right_top {
  border-bottom: #e5e5e5 1px solid;
  display: flex;
  justify-content: space-between;
}

.dian {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 9%;
  top: 45%;
}

.article_list_title {
  width: 280px;
  height: 61px;
  background: #c21d14;
  border-radius: 10px 10px 0px 0px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  padding-left: 29px;
  line-height: 61px;
}

.article_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 1200px;
  margin: 20px auto 94px auto;
}

.img_title {
  width: 100%;
  background: #ccc;
}

.article_list_box {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}
</style>
